.page-footer {
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 320px;
  right: 0;
  padding-right: 15px !important;
  border-top: 2px solid var(--dark);
  width: calc(100% - 320px);
  height: 45px;
  background-color: #fff;
}

@media screen and (max-width: 900px) {
  .page-footer {
    left: 0;
    width: 100%;
  }
}
