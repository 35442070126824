@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "Segoe";
  src: local("Segoe"),
    url("./fonts/Segoe UI.woff") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "SegoeBold";
  src: local("SegoeBold"),
    url("./fonts/Segoe UI Bold.woff") format("truetype");
}


body {
  margin: 0;
  // font-family: "Segoe", sans-serif;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #CFCFCF;
  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter" !important;
}

:root {
  --primary: #ffd300;
  --sec: #f58220;
  --dark: #dfdfdf;
  --light: #efefef;
  --blue: rgba(43, 51, 231, 1);
}

// ######################### Font Classes #####################
.segoe-normal {
  font-family: "Inter" !important;
}

.segoe-bold {
  font-family: "Inter" !important;
  font-weight: 600 ;
}

// ########################################## Common Components ##################################
.button-prime {
  font-family: "FuturaMedium";
  background-color: var(--sec);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 10px;
}

.button-prime:hover {
  color: #fff;
}

.button-prime:disabled {
  background-color: gray;
  cursor: default;
}

@media screen and (max-width: 600px) {
  .button-prime {
    font-size: 10px;
    padding: 3px 5px;
    margin-right: 5px;
  }
}

.button-sec {
  font-family: "FuturaMedium";
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  border: 1px solid var(--sec);
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 10px;
}

.button-link {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: #0d6efd;
  cursor: pointer;
}

.button-mobile {
  font-family: "FuturaMedium";
  background-color: #fff;
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  border: 2px solid var(--blue);
  padding: 2px 15px;
  margin-right: 10px;
}

.label-container {
  height: 100%;
  width: 100%;
  border: 1px solid var(--dark);
  background-color: var(--light);
  font-weight: 300;
  font-size: 15px;
  padding: 0.575em 0.75em 0.375em 0.75em;
}

.max-height {
  height: 100%;
}

// ######################### main container #################################

// .main-container {
//   min-height: calc(100vh - 210px);
//   width: calc(100vw - 330px);
//   margin: 130px 0px 80px 250px;
//   padding: 0em 2em;
// }
.main-container {
  min-height: calc(100vh - 210px);
  // width: calc(100vw - 330px);
  // margin: 130px 0px 80px 250px;
  display: flex;
  padding: 0em 2em;
  gap: 14px;
}

@media screen and (max-width: 900px) {
  .main-container {
    height: calc(100% - 210px);
    width: 100%;
    margin: 130px 0px 80px 0px;
    padding: 0em 0.4em;
  }
}

// ################################## chips wrapper #############################//
.chips-wrapper::-webkit-scrollbar {
  background: none;
  height: 7px;
  border-radius: 5px;
  cursor: pointer !important;
}
.chips-wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
  cursor: pointer !important;
}
.chips-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-left: 30px;
  overflow-x: auto;
  overflow-y: hidden;
}

.chip {
  background: #faeab6;
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 5px;
  color: #000;
  margin-bottom: auto;
}

// ########################## Accordion ##############################
.accordion-body {
  padding: 0px !important;
  font-family: "FuturaLight";
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
}

// ########################## Custom QM ##############################

.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  color: #6b1d73;
}

.table-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: center;

  /* Type colors / Secondary */

  color: #605e5c;
}

.MuiListItemText-primary{
  font-weight: 600 !important;
  font-size: 15px !important;
  
} 

.blue-dark{
  color:#0D3875 !important
}
.blue-dark-bg{
  background-color:#0D3875 !important
}

// .MuiSvgIcon-root{
//   background-color: "#6B1D73" !important 
// }
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover{
  background-color: #f2f2f2 !important;
}

.MuiDrawer-docked{}