.mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  width: 100%;
  background-color: rgba(253, 215, 166, 0.4);
  border-top: 2px solid var(--blue);
  display: flex;
  justify-content: space-around;
  padding: 8px 0px;
}
