@media screen and (max-width: 680px) {
  .table-container {
    overflow-x: scroll;
    width: 100%;
  }
  .table-box {
    min-width: 1960px;
  }
}

.thead {
  background-color: var(--primary);
  border: 1px solid #828282;
}

.th {
  border: 1px solid #828282;
  padding: 0.3em 0.6em;
  text-align: center;
}

.td {
  border: 1px solid #828282;
  padding: 0.4em 0.8em;
}
