@tailwind base;
@tailwind components;
@tailwind utilities;

label{
    font-weight: 600;
}
.image-upload > input
{
    display: none;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #0D3875;
    font-weight: 600 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #0D3875 !important;
}